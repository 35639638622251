<template>
  <v-container
    fluid
    class="px-lg-10 mx-0"
    style="background: #F5F5F5;"
  >
    <v-row class="py-0 mb-n5">
      <v-col class="text-left">
        <a
          href="#"
          style="text-decoration:none;"
          @click="cancelButtonClick"
        >
          <div class="d-inline-flex">
            <v-icon color="purple">
              mdi-chevron-left
            </v-icon>
            <p
              class="text-h4 my-3 mr-10"
              style="color: purple;"
            >
              Kembali
            </p>
          </div>
        </a>
      </v-col>
    </v-row>

    <v-row class="mb-n5">
      <v-col class="text-left">
        <p class="text-h2 font-weight-medium">
          Penarikan Detail
        </p>
      </v-col>
    </v-row>

    <v-card
      class="px-6 pt-5 pb-13"
      elevation="3"
    >
      <v-row>
        <v-col
          class="font-weight-bold"
          cols="12"
          sm="6"
          md="2"
        >
          Status
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          {{ dataWithdrawal.status_string }}
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="font-weight-bold"
          cols="12"
          sm="6"
          md="2"
        >
          Nama
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          {{ dataWithdrawal.rep_user_name }}
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="font-weight-bold"
          cols="12"
          sm="6"
          md="2"
        >
          Nominal Penarikan
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          Rp. {{ dataWithdrawal.nominal | dotThousandSeparator }}
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="font-weight-bold"
          cols="12"
          sm="6"
          md="2"
        >
          Tanggal Pengajuan
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          {{ dataWithdrawal.created_time }}
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="font-weight-bold"
          cols="12"
          sm="6"
          md="2"
        >
          Catatan
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-textarea
            v-if="dataWithdrawal.status === 1"
            ref="approvalReasonTextArea"
            v-model="approvalReason"
            color="purple"
            solo
            flat
            outlined
            no-resize
            dense
            placeholder="Harus diisi jika penarikan ditolak"
            :error-messages="approvalReasonErrorMessages"
            @input="eventInputApprovalReason"
          />
          <div v-else>
            {{ approvalReason }}
          </div>
        </v-col>
      </v-row>

      <v-row
        class="py-0 my-n2"
      >
        <v-col
          cols="12"
          sm="6"
          md="2"
        >
          <v-spacer />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <template v-if="isAllowModify">
            <v-btn
              v-if="enableRejectButton"
              color="red"
              @click="verifyButtonClick(0)"
            >
              TOLAK
            </v-btn>

            <v-btn
              v-if="enableAcceptButton"
              color="primary"
              @click="verifyButtonClick(1)"
            >
              SETUJU
            </v-btn>
          </template>
          <template v-else>
            <span>
              Tidak ada otorisasi
            </span>
          </template>
        </v-col>
      </v-row>

      <v-divider class="mt-8" />

      <time-line
        :data-timeline="dataLogWithdrawals"
        module="withdrawal"
      />
    </v-card>
  </v-container>
</template>

<script>
  // import pdf from 'vue-pdf'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      TimeLine: () => import('../../component/TimeLine'),
    },

    data: () => ({
      dataWithdrawal: {
        created_time: '',
        hash: '',
        nominal: 0,
        rep_user_entity: '',
        rep_user_entity_long: '',
        rep_user_id: '',
        rep_user_name: null,
        status: 1,
        status_string: '',
        updated_time: '',
        wallet_id: '',
        notes: '',
      },
      approvalReason: '',
      enableRejectButton: false,
      enableAcceptButton: false,
      enableDoneButton: false,
      isReadOnlyApprovalReason: true,
      isAllowModify: false,
      dataLogWithdrawals: [],
      approvalReasonErrorMessages: '',
      stepper: 1,
    }),

    computed: {
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id)
    },

    methods: {
      initialize (id) {
        const userMenus = localStorage.getItem('userMenus').split(',')
        if (userMenus.includes('WITHDRAWAL_MOD')) {
          this.isAllowModify = true
        }

        const vm = this
        const requestBody = {
          ww_hash: id,
        }
        axios.post('/t/withdrawal/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.dataWithdrawal = res.data.data.detail
            vm.approvalReason = vm.dataWithdrawal.notes
            // 1 = baru
            if (vm.dataWithdrawal.status === 1) {
              this.enableAcceptButton = true
              this.enableRejectButton = true
              this.isReadOnlyApprovalReason = false
            }
            // 2 = acc (dalam proses)
            if (vm.dataWithdrawal.status === 2) {
              this.enableDoneButton = true
              this.isReadOnlyApprovalReason = false
            }
            vm.dataLogWithdrawals = vm.dataWithdrawal.states
          }
        }).catch((e) => {
        })
      },

      verifyButtonClick (status) {
        if (!this.approvalReason && status === 0) {
          this.approvalReasonErrorMessages = 'Catatan harus diisi jika penarikan ditolak.'
          this.$refs.approvalReasonTextArea.focus()
          return
        }

        const requestBody = {
          withdraw_hash: this.dataWithdrawal.hash,
          app_status: status,
          reason: this.approvalReason === null ? '' : this.approvalReason,
        }

        axios.post('/t/withdrawal/mod/approval', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.initialize(this.$route.params.id)
            setTimeout(() => {
              this.$router.push({ name: 'Withdrawal' })
            }, 1500)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
        this.approvalReason = ''
      },

      eventInputApprovalReason () {
        this.approvalReasonErrorMessages = ''
      },

      cancelButtonClick () {
        this.$router.push({ name: 'Withdrawal' })
      },
    },
  }
</script>
